import { AsyncPipe } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, FormControl, FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { StaticDataModel, StaticDataType } from "@ankaadia/graphql";
import { TranslocoService } from "@jsverse/transloco";
import { noop } from "lodash";
import { MultiSelectModule } from "primeng-v17/multiselect";
import { Observable } from "rxjs";
import { AppendToBodyDirective } from "../../../shared/primeng/append-to-body/append-to-body.directive";
import { MultiSelectEditableColumnAutoFocusDirective } from "../../../shared/primeng/multi-select-editable-column-auto-focus/multi-select-editable-column-auto-focus.directive";
import { MultiSelectReadonlyFixDirective } from "../../../shared/primeng/multi-select-readonly-fix/multi-select-readonly-fix.directive";
import { MultiSelectSelectedItemsLabelDirective } from "../../../shared/primeng/multi-select-selectedItemsLabel/multi-select-selectedItemsLabel.directive";
import { MultiSelectSortSelectedValuesOnTopDirective } from "../../../shared/primeng/multi-select-sort-selected-values-on-top/multi-select-empty.directive";
import { StaticDataService } from "../../../shared/static-data/static-data.service";
import { TranslateDirective } from "../../../shared/transloco/translate.directive";

@Component({
  selector: "app-document-type-selector",
  templateUrl: "./document-type-selector.component.html",
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: DocumentTypeSelectorComponent, multi: true }],
  imports: [
    TranslateDirective,
    MultiSelectModule,
    AppendToBodyDirective,
    MultiSelectEditableColumnAutoFocusDirective,
    MultiSelectReadonlyFixDirective,
    MultiSelectSelectedItemsLabelDirective,
    MultiSelectSortSelectedValuesOnTopDirective,
    FormsModule,
    AsyncPipe,
  ],
})
export class DocumentTypeSelectorComponent implements OnInit, ControlValueAccessor {
  private readonly language = this.transloco.getActiveLang();

  protected model: string[] = [];
  protected disabled: boolean;
  protected types$: Observable<StaticDataModel[]>;

  onModelChange = noop;
  onModelTouched = noop;

  @Input()
  inputId: string;

  @Input()
  formControl: FormControl;

  constructor(
    private readonly changeDetector: ChangeDetectorRef,
    private readonly transloco: TranslocoService,
    private readonly staticData: StaticDataService
  ) {}

  ngOnInit(): void {
    this.types$ = this.staticData.getStaticData(StaticDataType.AllowedUploadFileTypes, this.language);
  }

  writeValue(model: string[]): void {
    this.model = model;
    this.changeDetector.markForCheck();
  }

  registerOnChange(onModelChange: () => void): void {
    this.onModelChange = onModelChange;
  }

  registerOnTouched(onModelTouched: () => void): void {
    this.onModelTouched = onModelTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.changeDetector.markForCheck();
  }

  protected updateModel(model: string[]): void {
    this.model = model;
    this.onModelChange(model);
    this.formControl?.setValue(model);
  }
}
